import request from '@/plugins/axios'

// 商品编辑
export const apiGoodsEdit = (data: any) =>
    request.post('/goods.goods/edit', data)

// 仓库列表
export const apiStoreHouseLists = (params: any) =>
    request.get('/warehouse.warehouse/lists', { params })

// 仓库管理列表
export const apiStorehouseList = (params: any) =>
    request.get('/warehouse.warehouse/lists', { params })
// 修改仓库状态
export const apiStorehouseStatus = (data: any) =>
    request.post('/warehouse.warehouse/edit', data)
// 添加仓库
export const apiStorehouseAdd = (data: any) =>
    request.post('/warehouse.warehouse/add', data)
// 仓库编辑
export const apiStorehouseEdit = (data: any) =>
    request.post('/warehouse.warehouse/edit', data)
// 仓库删除
export const apiStorehouseDel = (params: any) =>
    request.get('/warehouse.warehouse/del', { params })
// 仓库详情
export const apiStorehouseDetail = (id: number) =>
    request.get('/warehouse.warehouse/detail', { params: { id } })

// 小区管理列表
export const apiCommunityList = (params: any) =>
    request.get('/community.community/lists', { params })
// 修改小区状态
export const apiCommunityStatus = (data: any) =>
    request.post('/community.community/edit', data)
// 添加小区
export const apiCommunityAdd = (data: any) =>
    request.post('/community.community/add', data)
// 小区编辑
export const apiCommunityEdit = (data: any) =>
    request.post('/community.community/edit', data)
// 小区删除
export const apiCommunityDel = (params: any) =>
    request.get('/community.community/del', { params })
// 小区详情
export const apiCommunityDetail = (id: number) =>
    request.get('/community.community/detail', { params: { id } })

// 配送员管理列表
export const apiDeliverymanList = (params: any) =>
    request.get('/deliveryman.deliveryman/lists', { params })
// 修改配送员状态
export const apiDeliverymanStatus = (data: any) =>
    request.post('/deliveryman.deliveryman/edit', data)
// 添加配送员
export const apiDeliverymanAdd = (data: any) =>
    request.post('/deliveryman.deliveryman/add', data)
// 配送员编辑
export const apiDeliverymanEdit = (data: any) =>
    request.post('/deliveryman.deliveryman/edit', data)
// 配送员删除
export const apiDeliverymanDel = (params: any) =>
    request.get('/deliveryman.deliveryman/del', { params })
// 配送员详情
export const apiDeliverymanDetail = (id: number) =>
    request.get('/deliveryman.deliveryman/detail', { params: { deliveryman_id:id } })
// 配送员小区列表
export const apiGetCommunity = (params: any) =>
    request.get('/deliveryman.deliveryman/getCommunity', { params })
// 配送员小区添加
export const addCommunityDeliveryman = (params: any) =>
    request.post('/deliveryman.deliveryman/addCommunityDeliveryman', params)
// 配送员小区删除 
export const delCommunityDeliveryman = (params: any) =>
    request.post('/deliveryman.deliveryman/delCommunityDeliveryman', params)
// 配送员修改密码
export const changeDeliverymanPassword = (params: any) =>
    request.post('/deliveryman.deliveryman/editPassword', params)

// 配送报表
export const apiReportList = (params: any) =>
    request.get('/delivery_report.delivery_report/lists', { params })
// 站点配送报表
export const apiWarehouseReportList = (params: any) =>
    request.get('/delivery_report.delivery_report/warehouseList', { params })

// 配送详情列表
export const apiReportDetailList = (params: any) =>
    request.get('/delivery_detail.delivery_detail/getDeliveryDetailList', {
        params
    })
// 配送详情列表中的详情
export const apiOrderDetail = (params: any) =>
    request.get('/delivery_detail.delivery_detail/getOrderDetail', { params })
// 配送历史
export const apiDeliveryRecordList = (params: any) =>
    request.get('/delivery_detail.delivery_detail/getDeliveryRecordList', { params })
// 所有商品列表
export const apiGoodsAll = () =>
    request.get('/goods.goods/all')
// 添加配送历史备注
export const apiRemark = (params: any) =>
    request.post('/delivery_detail.delivery_detail/addDeliveryRecordRemark', params)

// 更改配送日期
export const apiChangeDate = (params:any) => 
    request.post('/delivery_detail.delivery_detail/delayDelivery', params)

// 暂停配送
export const apiPauseDelivery = (params:any) =>
    request.post('/delivery_detail.delivery_detail/pauseDelivery', params)

// 开启配送
export const apiStartDelivery = (params:any) =>
    request.post('/delivery_detail.delivery_detail/startDelivery', params)

// 更改配送规则
export const apiChangeDeliveryRecord = (params: any) => 
    request.post('/order.order/changeDeliveryRecord', params)