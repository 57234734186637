





































































































import { Component, Vue } from 'vue-property-decorator'
import { apiCommunityDelivery } from '@/api/data/data'
import { apiDeliverymanAll } from '@/api/order/order'
import {
    apiCommunityList, // 小区列表
} from '@/api/delivery'
import downloadExcel from 'vue-json-excel'
import { funDate } from '@/utils/util'

@Component({
    components: {
        downloadExcel,
    }
})
export default class FinanceProfile extends Vue {
    /** S Data **/
    ProfileData = {}
    timeData: any = []
    // 顶部查询表单
    form = {
        start_time: '',
        end_time: '',
        deliveryman_id: '',
        community_id: ''
    }
    loading = false
    exportData: any = [ ] // 导出的表格数据
    tableData: any = [] // 显示的表格数据

    deliverymanList: any = [] // 配送员列表
    communityList: any = [] // 小区列表
    // 头部文字
    header_text:any = ''
    json_fields = {
        "小区名称": "name",    //常规字段
        "配送数（盒数）":"num",
        "配送员":"deliveryman_name",
    }

    
    pickerOptions = {
          shortcuts: [{
            text: '最近一周',
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
    }
    /** E Data **/


    /** S Methods **/
    
    // 获取数据
    getData() {
        this.getExportData()
    }

     // 获取配送员列表
     getDeliverymanList() {
        apiDeliverymanAll({}).then(res => {
            this.deliverymanList = res
        })
    }

    // 配送小区列表
    getCommunityList() {
        apiCommunityList({page_size: 10000}).then((res:any) => {
            this.communityList = res.lists
        })
    }

    // 获取导出数据
    getExportData() {
        this.tableData = []
        this.exportData = []
        this.loading = true
        apiCommunityDelivery({
            ...this.form
        }).then(res => {
            this.loading = false
            console.log("res", res)
            this.exportData = [...res]
            this.tableData = [...res]
            console.log("this.tableData", this.tableData)
            let totle_obj:any = {
                name: '合计',
                num: 0,
                deliveryman_name: '',
            }
            res.forEach((item:any) => {
                totle_obj.num+= Number(item.num)
                console.log("item", item)
            })
            this.exportData.push({ ...totle_obj})
            console.log("this.exportData", this.exportData)
        })
    }
    // 时间分割
    splitTime() {
        if (this.timeData != null) {
            this.form.start_time = this.timeData[0]
            this.form.end_time = this.timeData[1]
        }
    }
    // 重置
    onReset() {
        let start_time: any = funDate(0);
        let end_time: any = funDate(0)
        this.timeData = [start_time, end_time]
        this.form.start_time = start_time
        this.form.end_time = end_time
        this.form.deliveryman_id = ''
        this.form.community_id = ''
        this.getExportData()
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        let start_time: any = funDate(0);
        let end_time: any = funDate(0)
        this.timeData = [start_time, end_time]
        this.form.start_time = start_time
        this.form.end_time = end_time
        this.getExportData()
         // 获取配送员列表
         this.getDeliverymanList()
        // 获取小区列表
        this.getCommunityList()
    }
    /** E Life Cycle **/
}
